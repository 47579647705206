@import "variables";
@import "mixins";
@import "mq";

// "Full width" Elements
%fw-container {
  width: 100%;
  max-width: 2560px;
  margin: auto;
}

// To constrain content inside a fw-container. This class should not be ON your fw-container, but an element within.
%constrain {
  max-width: $max-content-width;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;

  @include mq($from: M) {
    padding: 0;
  }
}

// Misc
.hidden {
  display: none;
}

%visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap; /* 1 */

  &.focusable:active,
  &.focusable:focus {
    clip: auto;
    clip-path: none;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
    white-space: inherit;
  }
}

.no-scroll {
  overflow: hidden;
}
