// Colours - BASE
$black: #000;
$white: #fff;
$darkgrey: #999;
$lightgrey: #eee;
$red: #f00;

$body-background-color: $lightgrey;
$input-background-color: $white;
$default-text-color: $black;
$secondary-text-color: $darkgrey;
$default-link-color: $black;
$hover-link-color: $darkgrey;
$border-color: $darkgrey;

// Fonts
$base-font: sans-serif;

$baseline: 1.25rem;

$h1: 3rem;
$h2: 2rem;
$h3: 1.5rem;
$h4: 1.25rem;
$h5: 1rem;

$h1-lg: 48px;
$h1-lg-lh: 1.25;

// Spacing
$space: 20px;
$content-width: 90%;
$max-content-width: 1200px;

// Modifiers
$border-radius: $space * 0.25;
$border-width: 2px;
$box-shadow: 0 10px 10px rgba($black, 0.025);
$opacity: 0.75;

//// Media Queries
// Uncomment this if your site isn't responsive. Enjoy your trip to the past!
//$mq-responsive: false;

// Responsive breakpoints, use semantic names
$mq-breakpoints: (
  XS: 320px,
  S: 480px,
  M: 720px,
  L: 980px,
  XL: 1024px,
  XXL: 1280px,
);

// Define the breakpoint from the $mq-breakpoints list that IE8 should get
$mq-static-breakpoint: XL;

// If you want to display the currently active breakpoint in the top
// right corner of your site during development, add the breakpoints
// to this list, ordered by width, e.g. (mobile, tablet, desktop).
$mq-show-breakpoints: () !default;

// Animation Variables
$transition-speed: 0.1s;
$transition-timing: ease-in-out;
