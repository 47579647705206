.app {
  $comp: &;

  @include mq($from: S) {
    height: 100%;
  }

  &__header {
    background: var(--color-secondary, $darkgrey);
    color: var(--color-primary, $white);
    padding: 20px;

    @include mq($from: S) {
      align-items: center;
      display: flex;
    }
  }

  &__logo {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    h1 {
      font-size: $h3;
      letter-spacing: -0.025rem;
      margin: 0 1rem 0 0;

      @include mq($until: S) {
        display: none;
      }
    }
  }

  &__brand-picker {
    margin-left: auto;
    text-align: left;
  }

  &__logo-image {
    display: block;
    max-height: $space * 2;
    width: auto;
  }

  &__inner {
    @extend %fw-container;
    display: grid;
    grid-gap: $space * 2;
    padding: $space;

    @include mq($from: S) {
      grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
    }
  }
}
