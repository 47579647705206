@import "mixins";
@import "mq";
@import "variables";

noscript {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  width: 100%;

  h2 {
    margin: 0;
    text-align: center;

    span {
      display: block;
      font-weight: normal;
      font-size: 5rem;
      margin-bottom: $baseline * 0.5;
    }
  }
}

[x-cloak] {
  display: none !important;
}

* {
  box-sizing: border-box;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

html {
  font-size: 1rem;
  text-rendering: optimizeLegibility;
}

body {
  background-color: $body-background-color;
  color: $default-text-color;
  font-family: $base-font;
  line-height: 1.75;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4 {
  line-height: 1.25;
  margin: 0 0 $baseline * 0.25;
}

h1 {
  font-size: $h1;
  letter-spacing: -0.075rem;
}

h2 {
  font-size: $h2;
  letter-spacing: -0.05rem;
}

h3 {
  font-size: $h3;
  letter-spacing: -0.025rem;
}

h4 {
  font-size: $h4;
}

h5 {
  font-size: $h5;
}

p {
  margin-top: 0;
  line-height: $baseline;
  margin-bottom: $baseline;
}

a {
  color: $default-link-color;
  text-decoration: none !important;

  &:hover {
    color: $hover-link-color;
  }
}

ul,
ol {
  margin: $baseline*0.5 0 0;
  padding-left: 18px;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

strong {
  font-weight: 600;
}

label:hover {
  cursor: pointer;
}

.show-vertical-rhythm {
  background-image: linear-gradient(
    rgba(200, 50, 50, 0.25) 1px,
    transparent 1px
  );
  background-size: 1px $baseline;
  padding-bottom: 1px;
  margin-bottom: $baseline;
}
