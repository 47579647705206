%field {
  -webkit-appearance: none;
  background: $input-background-color;
  border: $border-width solid $border-color;
  color: $black;
  font-size: 1rem;
  height: auto;
  margin: 0;
  min-height: $space * 2;
  padding: 5px;
  width: 100%;

  &:invalid {
    border-color: $red;
    color: $red;
  }

  &:active,
  &:focus {
    border-color: $black;
    border-radius: 0;
    outline: none;
  }
}

fieldset {
  border: 1px solid $border-color;
  border-radius: $border-radius;
  margin: 0 0 $baseline;
  padding: $space;

  &:last-child {
    margin-bottom: 0;
  }
}

label {
  display: block;
}

input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
input[type="radio"],
input[type="checkbox"] {
  @extend %field;
}

select {
  @extend %field;
  background-repeat: no-repeat;
  background-position: right 0.6rem center;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE2IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBkPSJNNy4yOTI4OSA4LjcwNzExQzcuNjgzNDIgOS4wOTc2MyA4LjMxNjU4IDkuMDk3NjMgOC43MDcxMSA4LjcwNzExTDE1LjA3MTEgMi4zNDMxNUMxNS40NjE2IDEuOTUyNjIgMTUuNDYxNiAxLjMxOTQ2IDE1LjA3MTEgMC45Mjg5MzJDMTQuNjgwNSAwLjUzODQwOCAxNC4wNDc0IDAuNTM4NDA4IDEzLjY1NjkgMC45Mjg5MzJMOCA2LjU4NTc5TDIuMzQzMTUgMC45Mjg5MzJDMS45NTI2MiAwLjUzODQwOCAxLjMxOTQ2IDAuNTM4NDA4IDAuOTI4OTMyIDAuOTI4OTMyQzAuNTM4NDA4IDEuMzE5NDYgMC41Mzg0MDggMS45NTI2MiAwLjkyODkzMiAyLjM0MzE1TDcuMjkyODkgOC43MDcxMVpNNyA3VjhIOVY3SDdaIiBmaWxsPSIjMDAwMDAwIj48L3BhdGg+DQo8L3N2Zz4NCg==");
  border-radius: 0;
  line-height: normal;
  padding-bottom: 0;
  padding-right: 30px;
  padding-top: 0;

  &::-ms-expand {
    display: none;
  }
}

button {
  background: var(--theme1-bg, $darkgrey);
  border: 0;
  border-radius: $border-radius;
  color: var(--theme1-fg, $white);
  cursor: pointer;
  min-height: 40px;
  padding: 0 1rem;

  span {
    @include mq($until: S) {
      display: none;
    }
  }
}

.row {
  margin-bottom: $baseline * 0.5;

  &:last-child {
    margin-bottom: 0;
  }

  &--multi {
    padding-top: $baseline * 0.5;

    @include mq($from: S) {
      display: grid;
      grid-gap: $baseline * 0.5;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
  }
}

.col {
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  &--checkbox,
  &--radio {
    label {
      align-items: center;
      background-color: $border-color;
      border-radius: $border-radius;
      color: $white;
      display: flex;
      font-size: 0.875rem;
      min-height: $space * 2;
      padding: 0 0.75rem 0 2rem;
      position: relative;
      width: 100%;

      &::before,
      &::after {
        content: "";
        position: absolute;
      }

      &::before {
        background-color: transparent;
        border: 1px solid $white;
        display: block;
        height: 1rem;
        left: 0.5rem;
        top: 11px;
        width: 1rem;
      }

      &::after {
        background-color: $white;
        display: none;
        height: 0.75rem;
        left: 11px;
        top: 14px;
        width: 0.75rem;
      }

      &.theme1 {
        background-color: var(--theme1-bg, $border-color);
        color: var(--theme1-fg, $white);

        &::before {
          border: 1px solid var(--theme1-fg, $white);
        }

        &::after {
          background-color: var(--theme1-fg, $white);
        }
      }

      &.theme2 {
        background-color: var(--theme2-bg, $border-color);
        color: var(--theme2-fg, $white);

        &::before {
          border: 1px solid var(--theme2-fg, $white);
        }

        &::after {
          background-color: var(--theme2-fg, $white);
        }
      }

      &.theme3 {
        background-color: var(--theme3-bg, $border-color);
        color: var(--theme3-fg, $white);

        &::before {
          border: 1px solid var(--theme3-fg, $white);
        }

        &::after {
          background-color: var(--theme3-fg, $white);
        }
      }

      &.theme4 {
        background-color: var(--theme4-bg, $border-color);
        color: var(--theme4-fg, $white);

        &::before {
          border: 1px solid var(--theme4-fg, $white);
        }

        &::after {
          background-color: var(--theme4-fg, $white);
        }
      }

      &.theme5 {
        background-color: var(--theme5-bg, $border-color);
        color: var(--theme5-fg, $white);

        &::before {
          border: 1px solid var(--theme5-fg, $white);
        }

        &::after {
          background-color: var(--theme5-fg, $white);
        }
      }
    }

    input[type="checkbox"],
    input[type="radio"] {
      display: none;
      pointer-events: none;

      &:checked + label::after {
        display: block;
      }

      &:focus + label::before {
        border-color: $black;
      }
    }

    input[type="radio"] + label {
      &::before,
      &::after {
        border-radius: 100%;
      }
    }
  }
}

.btn {
  &--copy {
    position: relative;

    &:not(:active):focus::after {
      animation-duration: 1s;
      animation-name: copy;
      animation-fill-mode: forwards;
      color: var(--theme1-bg, $default-text-color);
      content: "Copied";
      display: block;
      font-size: 0.75rem;
      left: 0;
      position: absolute;
      text-transform: uppercase;
      top: 50%;
      transform: translateX(-75%) translateY(-50%);
      z-index: -1;
    }
  }
}

@keyframes copy {
  0% {
    left: 110%;
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  100% {
    left: 140%;
    opacity: 0;
  }
}
