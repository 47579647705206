html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

noscript {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
}

noscript h2 {
  text-align: center;
  margin: 0;
}

noscript h2 span {
  margin-bottom: .625rem;
  font-size: 5rem;
  font-weight: normal;
  display: block;
}

[x-cloak] {
  display: none !important;
}

* {
  box-sizing: border-box;
}

html, body {
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

html {
  text-rendering: optimizelegibility;
  font-size: 1rem;
}

body {
  color: #000;
  background-color: #eee;
  font-family: sans-serif;
  line-height: 1.75;
  overflow-x: hidden;
}

h1, h2, h3, h4 {
  margin: 0 0 .3125rem;
  line-height: 1.25;
}

h1 {
  letter-spacing: -.075rem;
  font-size: 3rem;
}

h2 {
  letter-spacing: -.05rem;
  font-size: 2rem;
}

h3 {
  letter-spacing: -.025rem;
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1.25rem;
  line-height: 1.25rem;
}

a {
  color: #000;
  text-decoration: none !important;
}

a:hover {
  color: #999;
}

ul, ol {
  margin: .625rem 0 0;
  padding-left: 18px;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

strong {
  font-weight: 600;
}

label:hover {
  cursor: pointer;
}

.show-vertical-rhythm {
  background-image: linear-gradient(#c8323240 1px, #0000 1px);
  background-size: 1px 1.25rem;
  margin-bottom: 1.25rem;
  padding-bottom: 1px;
}

.app__inner {
  width: 100%;
  max-width: 2560px;
  margin: auto;
}

.brand-picker {
  max-width: 1200px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 45em) {
  .brand-picker {
    padding: 0;
  }
}

.hidden {
  display: none;
}

.brand-picker__grid-item span {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  white-space: nowrap;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.brand-picker__grid-item span.focusable:active, .brand-picker__grid-item span.focusable:focus {
  clip: auto;
  -webkit-clip-path: none;
  clip-path: none;
  height: auto;
  width: auto;
  white-space: inherit;
  margin: 0;
  position: static;
  overflow: visible;
}

.no-scroll {
  overflow: hidden;
}

.brand-picker__dropdown-toggle, select, input[type="date"], input[type="email"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="url"], input[type="radio"], input[type="checkbox"] {
  -webkit-appearance: none;
  color: #000;
  height: auto;
  min-height: 40px;
  width: 100%;
  background: #fff;
  border: 2px solid #999;
  margin: 0;
  padding: 5px;
  font-size: 1rem;
}

.brand-picker__dropdown-toggle:invalid, select:invalid, input[type="date"]:invalid, input[type="email"]:invalid, input[type="number"]:invalid, input[type="password"]:invalid, input[type="search"]:invalid, input[type="tel"]:invalid, input[type="text"]:invalid, input[type="url"]:invalid, input[type="radio"]:invalid, input[type="checkbox"]:invalid {
  color: red;
  border-color: red;
}

.brand-picker__dropdown-toggle:active, select:active, input[type="date"]:active, input[type="email"]:active, input[type="number"]:active, input[type="password"]:active, input[type="search"]:active, input[type="tel"]:active, input[type="text"]:active, input[type="url"]:active, input[type="radio"]:active, input[type="checkbox"]:active, .brand-picker__dropdown-toggle:focus, select:focus, input[type="date"]:focus, input[type="email"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="url"]:focus, input[type="radio"]:focus, input[type="checkbox"]:focus {
  border-color: #000;
  border-radius: 0;
  outline: none;
}

fieldset {
  border: 1px solid #999;
  border-radius: 5px;
  margin: 0 0 1.25rem;
  padding: 20px;
}

fieldset:last-child {
  margin-bottom: 0;
}

label {
  display: block;
}

select {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE2IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBkPSJNNy4yOTI4OSA4LjcwNzExQzcuNjgzNDIgOS4wOTc2MyA4LjMxNjU4IDkuMDk3NjMgOC43MDcxMSA4LjcwNzExTDE1LjA3MTEgMi4zNDMxNUMxNS40NjE2IDEuOTUyNjIgMTUuNDYxNiAxLjMxOTQ2IDE1LjA3MTEgMC45Mjg5MzJDMTQuNjgwNSAwLjUzODQwOCAxNC4wNDc0IDAuNTM4NDA4IDEzLjY1NjkgMC45Mjg5MzJMOCA2LjU4NTc5TDIuMzQzMTUgMC45Mjg5MzJDMS45NTI2MiAwLjUzODQwOCAxLjMxOTQ2IDAuNTM4NDA4IDAuOTI4OTMyIDAuOTI4OTMyQzAuNTM4NDA4IDEuMzE5NDYgMC41Mzg0MDggMS45NTI2MiAwLjkyODkzMiAyLjM0MzE1TDcuMjkyODkgOC43MDcxMVpNNyA3VjhIOVY3SDdaIiBmaWxsPSIjMDAwMDAwIj48L3BhdGg+DQo8L3N2Zz4NCg==");
  background-position: right .6rem center;
  background-repeat: no-repeat;
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 30px;
  line-height: normal;
}

select::-ms-expand {
  display: none;
}

button {
  background: var(--theme1-bg, #999);
  color: var(--theme1-fg, #fff);
  cursor: pointer;
  min-height: 40px;
  border: 0;
  border-radius: 5px;
  padding: 0 1rem;
}

@media (max-width: 29.99em) {
  button span {
    display: none;
  }
}

.row {
  margin-bottom: .625rem;
}

.row:last-child {
  margin-bottom: 0;
}

.row--multi {
  padding-top: .625rem;
}

@media (min-width: 30em) {
  .row--multi {
    grid-gap: .625rem;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    display: grid;
  }
}

.col {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.col--checkbox label, .col--radio label {
  color: #fff;
  min-height: 40px;
  width: 100%;
  background-color: #999;
  border-radius: 5px;
  align-items: center;
  padding: 0 .75rem 0 2rem;
  font-size: .875rem;
  display: flex;
  position: relative;
}

.col--checkbox label:before, .col--checkbox label:after, .col--radio label:before, .col--radio label:after {
  content: "";
  position: absolute;
}

.col--checkbox label:before, .col--radio label:before {
  height: 1rem;
  width: 1rem;
  background-color: #0000;
  border: 1px solid #fff;
  display: block;
  top: 11px;
  left: .5rem;
}

.col--checkbox label:after, .col--radio label:after {
  height: .75rem;
  width: .75rem;
  background-color: #fff;
  display: none;
  top: 14px;
  left: 11px;
}

.col--checkbox label.theme1, .col--radio label.theme1 {
  background-color: var(--theme1-bg, #999);
  color: var(--theme1-fg, #fff);
}

.col--checkbox label.theme1:before, .col--radio label.theme1:before {
  border: 1px solid var(--theme1-fg, #fff);
}

.col--checkbox label.theme1:after, .col--radio label.theme1:after {
  background-color: var(--theme1-fg, #fff);
}

.col--checkbox label.theme2, .col--radio label.theme2 {
  background-color: var(--theme2-bg, #999);
  color: var(--theme2-fg, #fff);
}

.col--checkbox label.theme2:before, .col--radio label.theme2:before {
  border: 1px solid var(--theme2-fg, #fff);
}

.col--checkbox label.theme2:after, .col--radio label.theme2:after {
  background-color: var(--theme2-fg, #fff);
}

.col--checkbox label.theme3, .col--radio label.theme3 {
  background-color: var(--theme3-bg, #999);
  color: var(--theme3-fg, #fff);
}

.col--checkbox label.theme3:before, .col--radio label.theme3:before {
  border: 1px solid var(--theme3-fg, #fff);
}

.col--checkbox label.theme3:after, .col--radio label.theme3:after {
  background-color: var(--theme3-fg, #fff);
}

.col--checkbox label.theme4, .col--radio label.theme4 {
  background-color: var(--theme4-bg, #999);
  color: var(--theme4-fg, #fff);
}

.col--checkbox label.theme4:before, .col--radio label.theme4:before {
  border: 1px solid var(--theme4-fg, #fff);
}

.col--checkbox label.theme4:after, .col--radio label.theme4:after {
  background-color: var(--theme4-fg, #fff);
}

.col--checkbox label.theme5, .col--radio label.theme5 {
  background-color: var(--theme5-bg, #999);
  color: var(--theme5-fg, #fff);
}

.col--checkbox label.theme5:before, .col--radio label.theme5:before {
  border: 1px solid var(--theme5-fg, #fff);
}

.col--checkbox label.theme5:after, .col--radio label.theme5:after {
  background-color: var(--theme5-fg, #fff);
}

.col--checkbox input[type="checkbox"], .col--checkbox input[type="radio"], .col--radio input[type="checkbox"], .col--radio input[type="radio"] {
  pointer-events: none;
  display: none;
}

.col--checkbox input[type="checkbox"]:checked + label:after, .col--checkbox input[type="radio"]:checked + label:after, .col--radio input[type="checkbox"]:checked + label:after, .col--radio input[type="radio"]:checked + label:after {
  display: block;
}

.col--checkbox input[type="checkbox"]:focus + label:before, .col--checkbox input[type="radio"]:focus + label:before, .col--radio input[type="checkbox"]:focus + label:before, .col--radio input[type="radio"]:focus + label:before {
  border-color: #000;
}

.col--checkbox input[type="radio"] + label:before, .col--checkbox input[type="radio"] + label:after, .col--radio input[type="radio"] + label:before, .col--radio input[type="radio"] + label:after {
  border-radius: 100%;
}

.btn--copy {
  position: relative;
}

.btn--copy:not(:active):focus:after {
  color: var(--theme1-bg, #000);
  content: "Copied";
  text-transform: uppercase;
  z-index: -1;
  font-size: .75rem;
  animation-name: copy;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateX(-75%)translateY(-50%);
}

@keyframes copy {
  0% {
    opacity: 0;
    left: 110%;
  }

  25% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    left: 140%;
  }
}

@media (min-width: 30em) {
  .app {
    height: 100%;
  }
}

.app__header {
  background: var(--color-secondary, #999);
  color: var(--color-primary, #fff);
  padding: 20px;
}

@media (min-width: 30em) {
  .app__header {
    align-items: center;
    display: flex;
  }
}

.app__logo {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.app__logo h1 {
  letter-spacing: -.025rem;
  margin: 0 1rem 0 0;
  font-size: 1.5rem;
}

@media (max-width: 29.99em) {
  .app__logo h1 {
    display: none;
  }
}

.app__brand-picker {
  text-align: left;
  margin-left: auto;
}

.app__logo-image {
  max-height: 40px;
  width: auto;
  display: block;
}

.app__inner {
  grid-gap: 40px;
  padding: 20px;
  display: grid;
}

@media (min-width: 30em) {
  .app__inner {
    grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
  }
}

.brand-picker {
  padding: 20px;
}

@media (min-width: 61.25em) {
  .brand-picker {
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.brand-picker h1 {
  color: #999;
  text-align: center;
  margin-bottom: 1.25rem;
  font-size: 2rem;
}

.brand-picker h1 small {
  letter-spacing: -.05rem;
  padding-top: 8px;
  font-size: 1.25rem;
  display: block;
}

@media (min-width: 30em) {
  .brand-picker h1 {
    margin: 0 0 2.5rem;
  }
}

.brand-picker__grid {
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  display: grid;
}

@media (min-width: 61.25em) {
  .brand-picker__grid {
    max-width: 90%;
  }
}

.brand-picker__grid-item {
  color: var(--color-primary, #999);
  text-align: center;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-weight: bold;
  line-height: 1.25;
  text-decoration: none;
  display: flex;
}

.brand-picker__grid-item:hover img {
  transform: scale(1.05);
}

.brand-picker__grid-image {
  max-height: 44px;
  width: auto;
  transition: all .3s ease-in-out;
  display: block;
}

.brand-picker__dropdown {
  min-width: 260px;
  margin-left: auto;
  position: relative;
}

.brand-picker__dropdown-toggle {
  color: #000;
  cursor: pointer;
  background-color: #fff;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE2IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBkPSJNNy4yOTI4OSA4LjcwNzExQzcuNjgzNDIgOS4wOTc2MyA4LjMxNjU4IDkuMDk3NjMgOC43MDcxMSA4LjcwNzExTDE1LjA3MTEgMi4zNDMxNUMxNS40NjE2IDEuOTUyNjIgMTUuNDYxNiAxLjMxOTQ2IDE1LjA3MTEgMC45Mjg5MzJDMTQuNjgwNSAwLjUzODQwOCAxNC4wNDc0IDAuNTM4NDA4IDEzLjY1NjkgMC45Mjg5MzJMOCA2LjU4NTc5TDIuMzQzMTUgMC45Mjg5MzJDMS45NTI2MiAwLjUzODQwOCAxLjMxOTQ2IDAuNTM4NDA4IDAuOTI4OTMyIDAuOTI4OTMyQzAuNTM4NDA4IDEuMzE5NDYgMC41Mzg0MDggMS45NTI2MiAwLjkyODkzMiAyLjM0MzE1TDcuMjkyODkgOC43MDcxMVpNNyA3VjhIOVY3SDdaIiBmaWxsPSIjMDAwMDAwIj48L3BhdGg+DQo8L3N2Zz4NCg==");
  background-position: right .6rem center;
  background-repeat: no-repeat;
  border: 0;
  align-items: center;
  padding: 4px 30px 4px 8px;
  line-height: normal;
  display: flex;
}

.brand-picker__dropdown-links {
  color: #000;
  width: 100%;
  background-color: #fff;
  position: absolute;
  box-shadow: 0 0 20px #131e291a;
}

.brand-picker__dropdown-links a {
  padding: 4px 8px;
  text-decoration: none;
  display: block;
}

.brand-picker__dropdown-links a:hover {
  text-decoration: none;
}

.brand-picker__dropdown-links a.current {
  opacity: .25;
  pointer-events: none;
}

.preview {
  background-color: #fff;
  padding: 20px;
}

.generate {
  padding: 1.25rem 0;
}

/*# sourceMappingURL=index.66e034b3.css.map */
